import React, { useEffect, useRef, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import { Typography } from 'antd';
import { Button, useTheme } from '@prio365/prio365-react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextArea from 'antd/lib/input/TextArea';
import { PrioTheme } from '../../../theme/types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getActiveDraftMessageId,
  getContactsByIdState,
  getUserMeContactId,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import UserAvatar from '../../../components/UserAvatar';
import { MessageId, ProjectId } from '../../../models/Types';
import { sendChatMessage } from '../api';
import { Contact } from '../../../models/Contact';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  messagesContainer: {
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing.regular,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.regular,
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.small,
    '&:hover $messageActions': {
      display: 'flex',
    },
  },
  messageBox: {
    alignItems: 'flex-start',
    padding: theme.spacing.regular,
    borderRadius: '0px 8px 8px 8px',
    width: '100%',
    fontSize: '14px',
    maxWidth: '91%',
    overflowWrap: 'break-word',
  },
  messageBoxUser: {
    backgroundColor: theme.colors.base.blue[30],
    borderRadius: '8px 0px 8px 8px',
    marginLeft: theme.spacing.large,
    marginRight: '0px',
  },
  messageBoxBuddy: {
    backgroundColor: '#EEF3FA',
    borderRadius: '0px 8px 8px 8px',
    marginLeft: '0px',
    marginRight: theme.spacing.large,
  },
  dateText: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.colors.application.typography.default,
  },
  dateTextUser: {
    textAlign: 'left',
    marginLeft: theme.spacing.large,
    paddingLeft: theme.spacing.small,
  },
  dateTextBuddy: {
    textAlign: 'right',
    marginRight: theme.spacing.large,
    paddingRight: theme.spacing.small,
  },

  messageFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },

  chatTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.small,
    justifyContent: 'flex-start',
    textAlign: 'right',
  },

  messageActions: {
    display: 'none',
    justifyContent: 'flex-start',
    marginRight: theme.spacing.large,
  },

  messageContainerHover: {
    '&:hover $messageActions': {
      display: 'flex',
    },
  },

  newMessageContainer: {
    display: 'flex',
    padding: theme.spacing.regular,
    flexDirection: 'column',
    gap: theme.spacing.small,
  },
  textAreaContainer: {
    display: 'flex',
    backgroundColor: '#fff',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing.small,
  },
  textAreaField: {
    padding: theme.spacing.small,
    height: '116px',
    border: '1px solid #E0E6EE',
    borderRadius: '2px',
    background: '#FFF',
    width: '100%',
    ' & .ant-input': {
      color: theme.colors.application.typography.default,
    },
  },
}));

interface PrioBuddyProps {
  messageId: string;
  projectId: ProjectId;
}

interface ChatMessage {
  sender: string;
  text: string;
  createdAt: string;
}

const PrioBuddy: React.FC<PrioBuddyProps> = (props) => {
  //#region ------------------------------ Defaults
  const { messageId, projectId } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userId = useSelector(getUserMeContactId);
  const contactsById = useSelector(getContactsByIdState);
  const activeDraftMessageId = useSelector<RootReducerState, MessageId>(
    (state) => getActiveDraftMessageId(state, projectId)
  );
  const [copied, setCopied] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([
    {
      sender: 'bot',
      text: t('common:widgetArea.chat.hello', {
        name: contactsById[userId]?.firstName,
      }),
      createdAt: new Date().toISOString(),
    },
  ]);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const KI: Contact = {
    contactId: 'PrioBuddy',
    firstName: 'PrioBuddy',
    lastName: '',
  };
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleCopy = (message) => {
    navigator.clipboard.writeText(message.text).then(() => {
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 1500);
    });
  };

  const scrollToLastMessage = () => {
    lastMessageRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  };

  const formatDate = (dateString) => {
    if (!dateString.endsWith('Z')) {
      dateString += 'Z';
    }
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} | ${date
      .toLocaleTimeString()
      .slice(0, -3)}`;
  };

  const handleSendMessage = async () => {
    if (userMessage.trim() === '') return;
    setIsSending(true);
    const { data } = await sendChatMessage(userMessage, messageId, projectId);
    setChatHistory((prev) => [
      ...prev,
      {
        sender: 'user',
        text: userMessage,
        createdAt: new Date().toISOString(),
      },
    ]);
    if (typeof data === 'string') {
      setChatHistory((prev) => [
        ...prev,
        { sender: 'bot', text: data, createdAt: new Date().toISOString() },
      ]);
    }
    scrollToLastMessage();
    setUserMessage('');
    setIsSending(false);
  };
  // #endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (activeDraftMessageId && messageId)
      setChatHistory([
        {
          sender: 'bot',
          text: t('common:widgetArea.chat.hello', {
            name: contactsById[userId]?.firstName,
          }),
          createdAt: new Date().toISOString(),
        },
      ]);
  }, [messageId, activeDraftMessageId, t, userId, contactsById]);

  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [chatHistory, t]);
  //#endregion

  return (
    <div className={classes.root}>
      <div className={classes.messagesContainer}>
        <div className={classes.chatTitle}>
          <UserAvatar
            contact={KI}
            backgroundColor="#1a2f41"
            size="medium"
            icon={'message-bot' as IconName}
          />
          <Typography.Title
            level={3}
            style={{
              color: theme.colors.application.typography.default,
              margin: 0,
            }}
          >
            {t('common:widgetArea.prioBuddy')}
          </Typography.Title>
        </div>
        {chatHistory?.map((message, index) => {
          const isLastMessage = index === chatHistory?.length - 1;
          const user = contactsById[userId];
          const creater = chatHistory[index]?.sender === 'user' ? user : KI;
          const isUser = creater === user;
          const messageBoxClass = isUser
            ? classes.messageBoxUser
            : classes.messageBoxBuddy;
          const dateTextClass = isUser
            ? classes.dateTextUser
            : classes.dateTextBuddy;

          const title = (
            <div>
              {!isUser && index > 1 && (
                <div className={classes.messageActions}>
                  <FontAwesomeIcon
                    icon={copied ? ['fal', 'check'] : ['fal', 'copy']}
                    style={{
                      paddingLeft: theme.spacing.small,
                      cursor: 'pointer',
                      color: copied ? '#4CAF50' : '#0052CC',
                      transition: 'color 0.3s ease',
                    }}
                    onClick={() => handleCopy(message)}
                  />
                  <FontAwesomeIcon
                    icon={['fal', 'message-arrow-down']}
                    style={{
                      padding: theme.spacing.small,
                      display: 'none', // vorerst nicht anzeigen
                      cursor: 'pointer',
                      color: '#0052CC',
                    }}
                    onClick={() => {}}
                  />
                </div>
              )}
            </div>
          );

          return (
            <div
              className={`${classes.messageContainer} ${classes.messageContainerHover}`}
            >
              <div
                key={index}
                ref={isLastMessage ? lastMessageRef : null}
                className={`${classes.messageBox} ${messageBoxClass}`}
              >
                <div
                  style={{
                    color: theme.colors.application.typography.default,
                    whiteSpace: 'pre-wrap',
                  }}
                  dangerouslySetInnerHTML={{ __html: message.text }}
                />
              </div>
              <div className={isUser ? '' : classes.messageFooter}>
                <div>{title}</div>
                <Typography className={`${classes.dateText} ${dateTextClass}`}>
                  {formatDate(message.createdAt)}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
      <div className={classes.newMessageContainer}>
        <div className={classes.textAreaContainer}>
          <TextArea
            placeholder={t('common:widgetArea.chat.newMessage')}
            autoSize={{ minRows: 3, maxRows: 3 }}
            maxLength={1024}
            showCount
            bordered={false}
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            className={classes.textAreaField}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                !e.shiftKey &&
                !e.ctrlKey &&
                !e.metaKey
              ) {
                e.preventDefault();
                if (userMessage.trim() !== '' && !isSending) {
                  handleSendMessage();
                }
              } else if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
                setUserMessage(userMessage + '\n');
              }
            }}
          />
        </div>
        <div className={classes.buttonsContainer}>
          <span></span>
          <Button
            type="primary"
            style={{}}
            iconProp={['fal', 'paper-plane-top']}
            onClick={() => handleSendMessage()}
            disabled={userMessage.trim() === '' || isSending}
            loading={isSending}
          >
            {t('common:widgetArea.chat.send')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrioBuddy;
