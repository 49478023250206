import { ApiResult, apiUrl } from '../../../api';
import { ProjectId } from '../../../models/Types';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const sendChatMessage: (
  userMessage: string,
  messageId: string,
  projectId: ProjectId
) => Promise<ApiResult<string>> = async (userMessage, messageId, projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/cognitive/EmailResponseAi/${messageId}?projectId=${projectId}&userMessage=${encodeURIComponent(
      userMessage
    )}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
