import { CalendarEvent } from './Calendar';
import {
  DateTimeString,
  DriveItemId,
  GroupId,
  MailCategoryColorName,
  MessageId,
  MeetingMessageType,
  MailFolderId,
  ProjectId,
  ContactId,
} from './Types';

// export interface MessageAbstraction {
//   id: string;
//   createdDateTime: string;
//   lastModifiedDateTime: string;
//   subject: string;
//   body: MessageBody;
//   sender: MessageRecipient;
//   from: MessageRecipient;
//   sentDateTime?: string;
// }

// export interface MessageThreadPost extends MessageAbstraction {
//   id: string;
//   createdDateTime: string;
//   lastModifiedDateTime: string;
//   changeKey: string;
//   categories: Array<string>;
//   receivedDateTime: string;
//   sentDateTime: string;
//   hasAttachments: boolean;
//   internetMessageId: string;
//   subject: string;
//   bodyPreview: string;
//   importance: string;
//   parentFolderId: string;
//   conversationId: string;
//   conversationIndex: string;
//   isDeliveryReceiptRequested?: unknown;
//   isReadReceiptRequested: boolean;
//   isRead: boolean;
//   isDraft: boolean;
//   webLink: string;
//   inferenceClassification: string;
//   body: MessageBody;
//   sender: MessageRecipient;
//   from: MessageRecipient;
//   toRecipients: MessageRecipient[];
//   ccRecipients: MessageRecipient[];
//   bccRecipients: MessageRecipient[];
//   replyTo: MessageRecipient[];
//   flag: any;
// }

export interface Message {
  id: MessageId;
  createdDateTime: DateTimeString; //relative
  lastModifiedDateTime: DateTimeString; //relative
  changeKey: string;
  categories: Array<string>;
  receivedDateTime: DateTimeString; //relative
  sentDateTime: DateTimeString; //relative
  hasAttachments: boolean;
  internetMessageId: string;
  subject: string;
  bodyPreview: string;
  importance: Importance;
  parentFolderId: string;
  conversationId: string;
  conversationIndex: string;
  isDeliveryReceiptRequested?: unknown;
  isReadReceiptRequested: boolean;
  isRead: boolean;
  isDraft: boolean;
  webLink: string;
  inferenceClassification: string;
  body: MessageBody;
  sender: MessageRecipient;
  from: MessageRecipient;
  toRecipients: MessageRecipient[];
  ccRecipients: MessageRecipient[];
  bccRecipients: MessageRecipient[];
  replyTo: MessageRecipient[];
  flag: FollowupFlag;
  attachments?: MessageAttachment[];
  messageAttachments?: MessageAttachment[];
  event?: CalendarEvent;
  meetingMessageType?: MeetingMessageType;
  customSingleValueExtendedProperties?: CustomSingleValueExtendedProperties;
}
export interface CustomSingleValueExtendedProperties {
  messageAssignmentId?: string;
  messageChatId?: string;
  messageTaskRelationId?: string;
  lastEmailInteractionType?: string;
  emailConversationId?: string;
}

export interface MessageCenterMessage extends Message {
  projectId?: ProjectId;
  projectName?: string;
  projectShortName?: string;
  shouldFetch?: boolean;
  mailFolderId?: MailFolderId;
  nextLink?: string;
  projectIdsToFetch?: {
    projectId: ProjectId;
    mailFolderId: MailFolderId;
  }[];
}

export interface MoveMessage extends Message {
  projectId?: ProjectId;
}

export type Importance = 'high' | 'normal' | 'low';

export const flagStatuses = ['NotFlagged', 'Complete', 'Flagged'] as const;

export type FlagStatus = (typeof flagStatuses)[number];
/**
 * 0: no flag
 * 1: completed
 * 2: flagged
 */
export interface FollowupFlag {
  completedDateTime?: any; //relative
  dueDateTime?: any; //relative
  startDateTime?: any; //relative
  flagStatus: FlagStatus;
  flag?: any;
}

export interface MessageBody {
  contentType?: string | number;
  content: string;
}
export interface MessageRecipient {
  emailAddress: {
    name?: string;
    address: string;
  };
}

export interface MessageUpdate {
  body?: MessageBody;
  from?: MessageRecipient;
  toRecipients?: MessageRecipient[];
  ccRecipients?: MessageRecipient[];
  bccRecipients?: MessageRecipient[];
  subject?: string;
  replyTo?: MessageRecipient[];
  importance?: Importance;
  event?: CalendarEvent;
}

export interface MessageAttachment {
  '@odata.type'?: string;
  contentType: string;
  contentId: string;
  id: string;
  isInline: boolean;
  lastModifiedDateTime: DateTimeString; //relative
  name: string;
  size: number;
  contentBytes?: string;
}

export interface MessageDriveItemMap {
  attachmentId: string;
  driveItemId: DriveItemId;
  driveItemName: string;
  emailDriveItemMapId: string;
  groupId: GroupId;
  messageId: MessageId;
  messageImmutableId: MessageId;
  parentReferenceId: DriveItemId;
  parentReferencePath: string;
  projectId: ProjectId;
  userId: ContactId;
}

export interface MasterCategory {
  id: string;
  color: MailCategoryColorName;
  displayName: string;
}

export interface SaveAttachmentsToCloud {
  targetDriveItemId?: DriveItemId;
  groupId: GroupId;
  attachments: {
    messageId: MessageId;
    attachmentId: string;
    fileName?: string;
    tags?: string[];
  }[];
}

export type EmailSearchResultType = 'contact' | 'project' | 'external';

export interface EmailSearchResult {
  id?: string;
  eMail: string;
  type: EmailSearchResultType;
  isSuggestion?: boolean;
}

export interface EmailSearchResultProject extends EmailSearchResult {
  projectName: string;
  projectNumber: string;
}

export interface EmailSearchResultContact extends EmailSearchResult {
  lastName: string;
  firstName: string;
}

export interface MessageMe {
  messages: Message[];
  nextLink: string;
}

export interface MessageToProjectRequest {
  destinationMailFolderId: MailFolderId;
  copyMessagesDtos: {
    messageId: MessageId;
    deleteMail: boolean;
    copyAttachments: boolean;
  }[];
}

export interface DriveItemToAttachmentRequest {
  groupId: GroupId;
  driveItemId: DriveItemId;
  messageId: MessageId;
}

export interface DriveItemsToAttachmentRequest {
  groupId: GroupId;
  driveItemIds: DriveItemId[];
}

export type MessageCategorizationPayload = {
  messages: MessageCategorization;
  isDelete: boolean;
};

export type MessageCategorization = {
  messageId: MessageId;
  categories: string[];
}[];

export type MarkAsReadPayload = { messageId: MessageId; isRead: boolean }[];

export type FlagMessagePayload = {
  messageId: MessageId;
  flag: FollowupFlag;
}[];

export interface MessageNextLinkPayload {
  messages: Message[];
  nextLink: string;
  resyncInSeconds: number;
}

export interface MessageDriveItemMap {
  emailDriveItemMapId: string;
  driveItemId: DriveItemId;
  messageImmutableId: MessageId;
  parentReferenceId: DriveItemId;
  messageId: MessageId;
  attachmentId: string;
  projectId: ProjectId;
  userId: ContactId;
}

export interface MailComment {
  emailCommentId?: string;
  emailConversationId?: string;
  createdAt?: string;
  createdBy?: string;
  isEdited?: boolean;
  comment: string;
  sharedMailboxId: string;
  messageId: string;
}
