import { useEffect, useMemo } from 'react';
import useOfficesContext from '../../../modules/companies/hooks/useOfficesContext';
import { FilterPickerConsumerProps } from './FilterPickerDynamicConsumer';
import useAccessRights2 from '../../../modules/users/hooks/useAccessRights2';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMe } from '../../../apps/main/rootReducer';
import useContactsContext from '../../../modules/contacts/hooks/useContactsProvider';
import {
  fetchExternalOffices,
  fetchInternalOffices,
} from '../../../modules/companies/actions';
import { sortOfficesHelper } from '../../../modules/companies/components/OfficeProvider';
import { Office } from '../../../models/Office';

function distinctByPropertyOfficeId(array: Office[]): Office[] {
  const seenOfficeIds = new Set<string>();
  return array.filter((item) => {
    const officeId = item.officeId;

    if (seenOfficeIds.has(officeId)) {
      return false;
    }
    seenOfficeIds.add(officeId);
    return true;
  });
}

interface OfficeFilterPickerConsumerProps extends FilterPickerConsumerProps {
  type?: 'all' | 'internal' | 'external';
}
const OfficeFilterPickerConsumer: React.FC<OfficeFilterPickerConsumerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { children, roles, type } = props;

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const {
    checkGlobalRoles,
    checkOfficeRolesOfOfficeId,
    checkProjectRolesOfOfficeId,
  } = useAccessRights2();

  const { getContactById } = useContactsContext();

  const {
    myOffices,
    allOffices,
    externalOffices,
    internalOffices,
    getOfficeById,
  } = useOfficesContext();

  const userMe = useSelector(getUserMe);
  const officeMe = useMemo(() => {
    return getOfficeById(getContactById(userMe?.id)?.officeId);
  }, [getOfficeById, getContactById, userMe]);

  const offices = useMemo(() => {
    const offices = allOffices.filter(({ officeType, name }) => {
      if (!name) {
        return false;
      }
      switch (type) {
        case 'internal': {
          return officeType === 'internalOffice';
        }
        case 'external': {
          return officeType === 'externalOffice';
        }
        default: {
          return true;
        }
      }
    });

    if (
      roles.globalRoles.length > 0 &&
      checkGlobalRoles(
        roles.globalRoles.filter((role) => role !== 'globalEmployee')
      )
    ) {
      return offices;
    }
    if (roles.officeRoles.length > 0 && roles.projectRoles.length > 0) {
      return offices.filter(
        (office) =>
          office.officeType === 'externalOffice' ||
          checkOfficeRolesOfOfficeId(roles.officeRoles, office?.officeId) ||
          checkProjectRolesOfOfficeId(roles.projectRoles, office?.officeId)
      );
    }

    if (roles.officeRoles.length === 0) {
      return offices.filter((office) => office.officeType === 'internalOffice');
    }

    return distinctByPropertyOfficeId(
      [
        ...(type !== 'external' ? [...myOffices, officeMe] : []),
        ...(type !== 'internal' ? externalOffices : []),
      ].filter((office) => !!office && office.name)
    ).sort(sortOfficesHelper);
  }, [
    roles,
    myOffices,
    officeMe,
    allOffices,
    externalOffices,
    type,
    checkGlobalRoles,
    checkOfficeRolesOfOfficeId,
    checkProjectRolesOfOfficeId,
  ]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (!officeMe || (internalOffices.length === 0 && type !== 'external')) {
      dispatch(fetchInternalOffices());
    }
  }, [officeMe, internalOffices.length, type, dispatch]);

  useEffect(() => {
    if (externalOffices.length === 0 && type !== 'internal') {
      dispatch(fetchExternalOffices());
    }
  }, [externalOffices.length, type, dispatch]);
  //#endregion

  return (
    <>
      {children({
        options: offices.map(({ officeId, name }) => {
          return {
            value: officeId,
            label: name,
            searchValue: `${name} ${officeId}`,
          };
        }),
      })}
    </>
  );
};

export default OfficeFilterPickerConsumer;
